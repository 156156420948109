// eslint-disable-line no-use-before-define
export default function zopimTJA(nombre, correo) {
  /*eslint-disable */
  var waitForZopim = setInterval(function () {
    if (window.$zopim === undefined || window.$zopim.livechat === undefined) {
      return;
    }

    $zopim(function () {
      $zopim.livechat.setName(nombre);
      $zopim.livechat.setEmail(correo);
    });
    clearInterval(waitForZopim);
  }, 2000);
  /*eslint-enable */
}
