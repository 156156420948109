import React from 'react'
import styles from './PreguntasFrecuentes.module.css'
import HelpIcon from '@material-ui/icons/Help';
export const PreguntasFrecuentes = () => {
    return (
        <a href="https://www.tjagto.gob.mx/preguntas-frecuentes-asesortja/" target="_blank" rel="noreferrer" className={styles.abrirPreguntasFrecuentes} >
          <HelpIcon style={{color: 'white'}} /> Preguntas Frecuentes
        </a>
    )
}
