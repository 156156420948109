import React from 'react'
import '../footer/FooterComponent.css'
export const FooterComponent = () => {

    const fechaHoy = new Date();
    
    

    return (
        <footer>
            Tribunal de Justicia Administrativa del Estado de Guanajuato {fechaHoy.getFullYear()}
        </footer>
    )
}
