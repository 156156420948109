import React from "react";
import styles from "./ListadoDefensoriasComponent.module.css";
import defensoriasList from "../../helpers/defensorias.json";

export const ListadoDefensoriasComponent = () => {
  const [defensorias, setDefensorias] = React.useState([]);

  React.useEffect(() => {
    setDefensorias(defensoriasList);
  }, []);

  return (
    <>
      <div className="container-fluid">
          <div className="row">
                <div className="col-12 my-2">
                    <h4 className="text-white">Conoce nuestras defensorías de oficio. </h4>
                </div>
          </div>
        <div className="row">
          {defensorias.map((defensoria) => (
            <div className="col-md-6 col-sm-12" key={defensoria.id}>
              <div className="card mb-2">
                <div className="card-body">
                  <h6> {defensoria.municipio}</h6>
                  <p style={{ fontSize: "0.7rem" }}>
                    <a href={`tel:${defensoria.telefono1}`}>
                      {defensoria.telefono1}
                    </a>
                    <br></br>
                    <a href={`tel:${defensoria.telefono2}`}>
                      {defensoria.telefono2}
                    </a>
                    <br></br>
                    <a href={`${defensoria.mapsUrl}`}>Abrir Detalles</a>
                    <br></br>
                    {defensoria.nombreContato}
                    <br></br>
                    <a href={`mailto:${defensoria.correoElectronicoContacto}`}>
                      {defensoria.correoElectronicoContacto == "" ? (<>N/A</>): defensoria.correoElectronicoContacto}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
