import React from "react";
import styles from "./NoticiasComponent.module.css";
import axios from "axios";
import parse from "html-react-parser";

export const NoticiasComponent = () => {
  const [noticias, setNoticias] = React.useState([]);
  const [noticiasLoaded, setNoticiasLoaded] = React.useState(false);

  React.useEffect(() => {
    axios
      .get(`https://www.tjagto.gob.mx/wp-json/wp/v2/posts/?per_page=2`)
      .then((res) => {
        const Final = res.data;
        setNoticias(res.data);
        setNoticiasLoaded(true);
      });
  }, []);

  return (
    <div className={`${styles.noticiaBar} d-none d-sm-block`}>
      {noticiasLoaded ? (
        noticias.map((noticia) => (
          <div key={noticia.id}>
            <a
              href={noticia.link}
              target="_blank"
              rel="noreferrer"
            >
              {parse(noticia.title.rendered)}
            </a>
            <br></br>
          </div>
        ))
      ) : (
        <> Cargando Noticias </>
      )}
    </div>
  );
};
