import React from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ChatComponent } from "../chat/ChatComponent";
import Estados from "../../helpers/estados.json";
import EstadoMunicipios from "../../helpers/estados-municipios.json";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import axios from "axios";
import { nanoid } from "nanoid";
import MenuItem from "@material-ui/core/MenuItem";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import styles from "./FormularioIniciarComponent.module.css";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import ReCAPTCHA from "react-google-recaptcha";

const validationSchema = yup.object({
  email: yup
    .string("Ingrese Correo Electrónico")
    .email("Ingrese un correo válido.")
    .required("Correo Electrónico es requerido"),
  nombre: yup
    .string("Ingrese Nombre para continuar")
    .required("El nombre es requerido"),
  descripcion: yup
    .string("Ingrese Nombre para continuar")
    .required("La Descripción es necesaria"),
});

export const FormularioIniciarComponent = (props) => {
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [disabledButtonLogin, setDisabledButtonLogin] = React.useState(true);

  React.useEffect(() => {
    setMunicipios(EstadoMunicipios["Guanajuato"]);

    toast.info("Te pedimos llenar la siguiente información para ser atendido", {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  function getEstados() {
    setEstados(Estados);
  }

  function getMunicipios(estado) {
    if (estado.target.value === "Seleccionar Estado") {
      setMunicipios([]);
    } else {
      const estadoFinal = estado.target.value;

      const valor = EstadoMunicipios[estadoFinal];

      setMunicipios(valor);
    }
  }

  function onChange(value) {
    if (value) {
      setDisabledButtonLogin(false);
    } else {
      setDisabledButtonLogin(true);
    }
  }

  const sendMail = (values) => {
    var data = {
      service_id: "service_krcjwhi",
      template_id: "template_xinurrm",
      user_id: "user_3U1IjFWHwuFanccLIuOwo",
      template_params: values,
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", data)
      .then(function (response) {
        if (response.status === 200) {
          toast.success("Correo electrónico mandado correctamente");
        } else {
          toast.error("Tenemos problemas para mandar el correo");
          console.error(response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getEstados();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      nombre: "",
      descripcion: "",
      estado: "Guanajuato",
      municipio: "",
      reply_to: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (disabledButtonLogin == false) {
        localStorage.clear();
        localStorage.setItem("chatInformation", JSON.stringify(values));
        sendMail(values);
        props.setDivComenzarChat(true);
      }
    },
  });

  return (
    <>
      <ChatComponent></ChatComponent>
      <div className="card p-3 animate__animated animate__fadeIn">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <div className="my-0">
                  <div class="alert alert-primary" role="alert">
                    Te invitamos a completar la siguiente información para
                    brindarte la asesoría especializada.
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    className="mb-2"
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nombre && Boolean(formik.errors.nombre)
                    }
                    helperText={formik.touched.nombre && formik.errors.nombre}
                  />
                  <TextField
                    fullWidth
                    className="mb-2"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    fullWidth
                    className="mb-2"
                    id="descripcion"
                    name="descripcion"
                    label="Describe brevemente la situación"
                    multiline
                    rows={4}
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.descripcion &&
                      Boolean(formik.errors.descripcion)
                    }
                    helperText={
                      formik.touched.descripcion && formik.errors.descripcion
                    }
                  />

                  <FormControl fullWidth>
                    <InputLabel htmlFor="estado">Estado</InputLabel>
                    <Select
                      autoWidth
                      native
                      className="mb-2"
                      value={formik.values.estado}
                      onChange={(e) => {
                        formik.handleChange(e);
                        getMunicipios(e);
                      }}
                      inputProps={{ name: "estado", id: "estado" }}
                    >
                      <option aria-label="Seleccionar Estado">
                        Seleccionar Estado
                      </option>

                      {estados.map((estado, index) => (
                        <option key={index} value={estado.nombre}>
                          {estado.nombre}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="municipio">Municipio</InputLabel>
                    <Select
                      className="mb-2"
                      autoWidth
                      native
                      value={formik.values.municipio}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      inputProps={{ name: "municipio", id: "municipio" }}
                    >
                      <option aria-label="Seleccionar Municipio">
                        Seleccionar Municipio
                      </option>

                      {municipios.map((municipio, index) => (
                        <option key={index} value={municipio}>
                          {municipio}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <br />
                  <br />
                  <br />

                  <div className="text-center center-text d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey="6LeYQ0QkAAAAACCKhugqKPRV1vPCFyyBQhTj808c"
                      onChange={onChange}
                    />
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    disabled={disabledButtonLogin}
                    fullWidth
                    type="submit"
                    className="mt-3"
                  >
                    Comenzar Chat
                  </Button>

                  <div className="my-2 text-center">
                    <a
                      href="https://www.tjagto.gob.mx/aviso-de-privacidad-simplificado/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-center"
                    >
                      Todos los datos ingresados estan protegidos por nuestro{" "}
                      <br></br>
                      <strong>Aviso de privacidad</strong>
                    </a>
                  </div>
                </form>
              </div>
              <div className="col-md-4">
                <a
                  href="https://www.tjagto.gob.mx/enlinea/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Promocional"
                    style={{ width: "100%" }}
                    className="m-1 border-1"
                    src="https://revistaija.tjagto.gob.mx/wp-content/uploads/2021/08/jl.png"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
