import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'
import { FooterComponent } from './components/footer/FooterComponent';
import { BlobsComponent } from './components/blobs/BlobsComponent';
import { NoticiasComponent } from './components/noticias/NoticiasComponent';
import { PreguntasFrecuentes } from './components/PreguntasFrecuentes/PreguntasFrecuentes';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <FooterComponent />
    <BlobsComponent />
    <NoticiasComponent />
    <PreguntasFrecuentes />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
