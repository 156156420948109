import React from "react";
import styles from "./InstruccionesDeUsoComponent.module.css";
import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
export const InstruccionesDeUsoComponent = () => {
  const [whatsAppInfo, setWhatsAppInfo] = React.useState(
    JSON.parse(localStorage.getItem("chatInformation"))
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5>Todo listo!</h5>
        <p>
          Puedes comunicarte de manera electrónica a travez de las siguientes
          plataformas:
        </p>
        <ReactWhatsapp
          className="btn btn-success"
          number="5214612530059"
          message={`Nombre: ${whatsAppInfo.nombre} Correo: ${whatsAppInfo.email} Estado: ${whatsAppInfo.estado} Municipio: ${whatsAppInfo.municipio} Descripción: ${whatsAppInfo.descripcion}`}
        >
          <WhatsAppIcon /> Contactar a asesor por Whatsapp
        </ReactWhatsapp>
        <br></br>
        <br></br>
        <p><small>O si lo deseas puedes</small></p>
        <a
          className="btn btn-success"
          href={`mailto:aesquivelpa@guanajuato.gob.mx;kaseguraesq@gmail.com;cestradagar@guanajuato.gob.mx?subject=Solicitud%20Contacto%20Asesor@TJA&body=${encodeURIComponent(
            whatsAppInfo.nombre.trim()
          )}%0A${encodeURIComponent(whatsAppInfo.email.trim())}
          %0A${encodeURIComponent(whatsAppInfo.estado.trim())}
          %0A${encodeURIComponent(whatsAppInfo.municipio.trim())}
          %0A${encodeURIComponent(whatsAppInfo.descripcion.trim())}`}
        >
          <MailIcon /> Enviar Correo Electrónico
        </a>

        <p className="fw-light pt-3" style={{fontSize: '10px'}}>
            Instrucciones de uso:
          <small>
            <ul>
              <li>Selecciona el medio de contacto</li>
              <li>
                Realiza una conversación con el asesor explicando a detalle su
                situación
              </li>
              <li>
                Al terminar la conversación con el asesor cierre la ventana
                actual.
              </li>
            </ul>
          </small>
        </p>
      </div>
    </div>
  );
};
