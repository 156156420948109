import React from "react";
import { FormularioIniciarComponent } from "./FormularioIniciarComponent";
import ReactWhatsapp from "react-whatsapp";
import { InstruccionesDeUsoComponent } from "../InstruccionesDeUsoComponent/InstruccionesDeUsoComponent";
import { ListadoDefensoriasComponent } from "../ListadoDefensoriasComponent/ListadoDefensoriasComponent";
import styles from "./FormularioIniciarComponent.module.css";

export const InicioComponent = () => {
  const [botonActivo, SetBotonActivo] = React.useState(false);
  const [formIsVisible, setFormIsVisible] = React.useState(false);
  const [Divbienvenida, setDivBienvenida] = React.useState(true);
  const [DivComenzarChat, setDivComenzarChat] = React.useState(false);

  return (
    <>
      {DivComenzarChat ? (
        <>
          <div className="container py-5" style={{ height: "100vh" }}>
            <div className="row d-flex align-items-center d-flex justify-content-evenly" style={{minHeight: "100vh"}}>
              <div className="col-md-6"><InstruccionesDeUsoComponent/></div>
              <div className="col-md-6"><ListadoDefensoriasComponent /></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container py-5" style={{ height: "100vh" }}>
            <div
              className="row d-flex align-items-center d-flex justify-content-evenly"
              style={{ height: "100vh" }}
            >
              <div className={`col-md align-self-center animate__animated animate__zoomIn text-center ${styles.logoTJA}`}>
                <img
                  style={{ width: "90%", margin: "0 auto" }}
                  src="https://www.tjagto.gob.mx/wp-content/uploads/2021/04/logotipoasesor.svg"
                  alt="Asesor en línea TJA"
                />
              </div>
              <div className="col-md-1"></div>
              <div
                className="col-md-7 align-self-center py-5"
                style={{ textAlign: "justify", fontSize: "13px" }}
              >
                {formIsVisible ? <FormularioIniciarComponent setDivComenzarChat={setDivComenzarChat} /> : ""}

                {Divbienvenida ? (
                  <div className="card p-3 animate__animated animate__fadeIn animate__delay-1s">
                    <div className="card-body">
                      <h5>
                        "La utilización de este servicio implica la aceptación
                        expresa de las políticas de uso siguientes:
                      </h5>

                      <br></br>
                      <ol>
                        <li>
                          Al comenzar la asesoría en línea, el asesor preguntará
                          al usuario su nombre, correo electrónico, teléfono y
                          lugar de residencia, datos que deberá proporcionar.
                        </li>
                        <li>
                          El servicio se proporcionará sobre consultas
                          concretas, serán confidenciales y gratuitas para el
                          usuario.
                        </li>
                        <li>
                          El usuario será responsable de sus manifestaciones y
                          de todo acto que realice u omita en la sesión.
                        </li>
                        <li>Se prohíbe el uso de lenguaje ofensivo.</li>
                        <li>
                          El asesor se reserva el derecho de dar por terminada
                          una sesión de asesoría en línea, sin previo aviso, a
                          quien no respete las políticas de uso o se demore en
                          responder por más de cinco minutos.
                        </li>
                      </ol>

                      <p>
                        La información aquí proporcionada no es vinculante, ni
                        refleja una posición institucional<br></br> <br></br>
                        Con fundamento en los artículos 1 y 2 de la Ley Orgánica
                        del Tribunal de lo Contencioso Administrativo del Estado
                        de Guanajuato; 1, 2 fracción V, 6 fracción I de la Ley
                        de Protección de Datos Personales para el Estado y los
                        Municipios de Guanajuato; y 8 de los lineamientos de la
                        Ley de Protección de Datos Personales para el Estado y
                        los Municipios de Guanajuato; los datos personales
                        recabados para la asesoría en línea serán protegidos,
                        incorporados y tratados en el banco de datos de Usuarios
                        de Asesoría en Línea de la Defensoría de Oficio del
                        Tribunal de Justicia Administrativa a cargo de la
                        Coordinación de la Unidad de Defensoría de Oficio de
                        este órgano jurisdiccional, cuyo destino es para fines
                        estadísticos y no serán cedidos por ningún medio a
                        terceros."
                      </p>

                      <hr></hr>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={() => {
                            SetBotonActivo(!botonActivo);
                          }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          He leído el{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.tjagto.gob.mx/aviso-de-privacidad-simplificado/"
                          >
                            Aviso de Protección de Datos Personales,
                          </a>
                          acepto los términos y condiciones y deseo continuar.
                        </label>

                        <div className="d-grid gap-2 pt-3">
                          <button
                            className="btn btn-success"
                            type="button"
                            disabled={!botonActivo}
                            onClick={() => {
                              setFormIsVisible(!formIsVisible);
                              setDivBienvenida(false);
                            }}
                          >
                            Comenzar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
