import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { InicioComponent } from "./components/inicio/InicioComponent";
import { ScreenChatComponent } from "./components/chat/ScreenChatComponent";
import "animate.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
function App() {
  localStorage.setItem("APIKEYSEPOMEX", "ae2d843a-6043-4817-92f4-22d852fb5d87");

  return (
    <>
     <ToastContainer />
      <HelmetProvider>
        <Router>
          <Switch>
            <Router exact path="">
              <InicioComponent />
            </Router>
          </Switch>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
