import React, { useEffect, useState } from "react";
import ReactZenDeskChat from "@inlightmedia/react-zendesk-chat";
import { Helmet, HelmetProvider } from "react-helmet-async";
import zopimTJA from '../../helpers/zopim';
export const ChatComponent = (props) => {
  const [scriptName, setScriptName] = useState();

  useEffect(() => {
    zopimTJA("Carlos Francisco Estrada G", "carlos@wiboo.com.mx");
  }, []);

  return (
    <>
      <ReactZenDeskChat appID="2D1FrdTbVNnn6VZ2E4ggcjql9tSLD3vj" />
      {scriptName}
    </>
  );
};
